import React from 'react';
import constructionIcon from '../assets/construction-icon.png'; // Import construction icon
import './UnderConstruction.css'; // Import UnderConstruction.css for styling


function UnderConstruction() {
  return (
    <div className="page-container">
      <h1 className="page-title">Website Under Construction</h1>
      <img src={constructionIcon} alt="Construction Icon" className="page-icon" />
      <p className="page-text">We're working on something awesome. Please check back later!</p>
    </div>
  );
}

export default UnderConstruction;
