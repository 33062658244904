// src/pages/PrivacyPolicy.js

import React from 'react';
import privacyIcon from '../assets/privacy-icon.png'; // Import privacy icon


function PrivacyPolicy() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <img src={privacyIcon} alt="Privacy Icon" />
      <p>This is the privacy policy content. Replace this with your actual privacy policy.</p>
    </div>
  );
}

export default PrivacyPolicy;
